import React from "react";
import { graphql, Link } from "gatsby";
import get from "lodash/get";
import Helmet from "react-helmet";
import Layout from "../components/layout";
import ArticlePreview from "../components/article-preview";
import introImg from "../images/introhImg.jpg"

class RootIndex extends React.Component {
  render() {
    const siteTitle = "Fornbod";
    const posts = get(this, "props.data.allContentfulBlogPost.edges");

    return (
      <Layout location={this.props.location}>
        <div style={{ background: "#fff" }}>
          <Helmet title={siteTitle} />
          <div className="wrapper">
            <div className="introWrapper">
              <div className="introElement">
                <h1>Fornbods Samfällighetsförening</h1>
                <p>
                  Horns kustväg mellan Alvedsjöbodar och Byrums Sandvik på Öland
                  är en av världens vackraste vägar.
                </p>
              </div>
              <div className="introElement">
                <img alt="utsikt" src={introImg}></img>
              </div>
            </div>
            <div className="about">
              <h2>Om Samfälligheten</h2>
              <p>Fornbods samfällighetsförening är en samfällighet på norra Öland vars medlemmar ansvarar för underhållet av den enskilda vägen Horns Kustväg.</p>
              <Link to="/om">Läs mer</Link>
            </div>
            <h2 className="section-headline">Händelser</h2>
            <ul className="article-list">
              {posts.map(({ node }) => {
                return (
                  <li key={node.slug}>
                    <ArticlePreview article={node} />
                  </li>
                );
              })}
            </ul>
            <div className="view-blog-wrap">
              <a className="view-blog"><Link to="/blog">Visa alla händelser</Link></a>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default RootIndex;

export const pageQuery = graphql`
  query HomeQuery {
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }, limit: 3) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "D MMMM YYYY", locale: "sv")
          # tags
          heroImage {
            fluid(maxWidth: 350, maxHeight: 196) {
              ...GatsbyContentfulFluid
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulPerson(
      filter: { contentful_id: { eq: "15jwOBqpxqSAOy2eOO4S0m" } }
    ) {
      edges {
        node {
          name
          shortBio {
            shortBio
          }
          title
          heroImage: image {
            fluid(
              maxWidth: 1180
              maxHeight: 480
              resizingBehavior: PAD
              background: "rgb:000000"
            ) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`;